.chrome-permission-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: #000000e1;
  color: white;
  overflow: auto;

  .right-top-arrow {
    position: absolute;
    right: 15vw;
    top: 25px;
    width: 5vw;
  }
  
  .close-btn {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 10000;

    .close-icon {
      font-size: 2.5vw;
      color: #c9c9c9;
    }
  }

  .close-btn:hover {
    background-color: #605e5e7a !important;
  }

  &::-webkit-scrollbar {
    width: 20px !important;
  }

  .title {
    margin: 16px 0px;
    font-size: 1.8vw;
  }

  .subtitle {
    font-weight: 500;
    font-size: 1.2vw;
    margin-bottom: 2.5vw;
  }

  .instructions {
    margin-top: 120px;
    width: 100%;
    flex: 1;
    align-items: center;

    .chrome-instruction {
      width: 50vw;
    }

    .steps {
      align-items: flex-start;

      .access-text {
        font-weight: 500;
      }
    
      .step {
        margin: 12px 0px;
        font-size: 1.1vw;
        font-weight: 500;

        .blocked-icon {
          width: 1.5vw;
          height: 1.5vw;
          margin: 0px 8px;
        }
  
        .step-no {
          align-items: center;
          justify-content: center;
          font-size: 1.25vw;
          font-weight: 600;
          width: 1.8vw;
          height: 1.8vw;
          border-radius: 50%;
          background-color: white;
          color: #BF5AF2;
          line-height: 1;
          margin-right: 16px;
        }
      }
    }
  }
}