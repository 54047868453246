.offline-dialog-container {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0,0,0,0.8);

    .offline-dialog {
        position: absolute;
        height: min-content;
        width: 600px;
        padding: 20px;
        border-radius: 10px;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        background: white;
        
        .offline-dialog-title {
            font-size: 22px;
            font-weight: 600;
        }
        
        .offline-dialog-text {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 500;
        }
    }
}
