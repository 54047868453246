.full-page-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #10002b;
}

@media only screen and (max-width: 600px) {
  .full-page-loader {
    height: 100dvh;
  }
}