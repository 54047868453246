.row-flex {
    display: flex;
    flex-direction: row;
}

.flex {
    display: flex;
}

.col-flex {
    display: flex;
    flex-direction: column;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.sm-show {
    display: none;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.align-start {
    align-items: flex-start;
}

.wrap {
    flex-wrap: wrap;
}

.container {
    padding: 20px 30px;
}

.box-gray {
    border-radius: 20px;
    border: 1px solid;
    background: #eaeaea;
}

.relative {
    position: relative;
}

.fit-content {
    width: fit-content;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.border {
    border: 1px solid;
}

.clickable{
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .sm-col {
        flex-direction: column;
    }
    
    .sm-row {
        flex-direction: row;
    }

    .sm-justify-center {
        justify-content: center;
    }

    .sm-align-center {
        align-items: center;
    }

    .sm-align-start {
        align-items: flex-start;
    }

    .sm-100 {
        width: 100%;
    }

    .sm-w-50 {
        width: 50%;
    }

    .sm-w-100 {
        width: 100%;
    }

    .sm-show {
        display: flex;
    }

    .sm-hide {
        display: none !important;
    }
}