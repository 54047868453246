.dialogContainer {
  width: 500px !important;
}

.dialogActions {
  padding: 16px 24px !important;
  padding-top: 0px !important;
}

.dismissBtn {
  color: #be5af2 !important;
}