.permission-message-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0,0,0,0.9);
  z-index: 2000;

  .arrow {
      position: absolute;
      top: 150px;
      left: 200px;
  }

  .message {
      position: absolute;
      top: 300px;
      left: 100px;
      width: 60%;
      font-size: 18px;
      font-weight: 600;
      color: white;
      text-align: start;
  }
}