html {
  font-size: 100%;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: unset;
}

body {
  --screen-ratio: 1vw;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (-webkit-min-device-pixel-ratio: 1) {
  html,
  body {
    --screen-ratio: 0.86vw;
  }
}

@media (-webkit-min-device-pixel-ratio: 1.5) {
  html,
  body {
    --screen-ratio: 0.86vw;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) {
  html,
  body {
    --screen-ratio: 1vw;
  }
}
