.outer-container {
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 101;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: black;

  .inner-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 70vw;
    height: 40vw;
    background: #ffffff;
    border-radius: 12px;
    text-align: start;

    .close-button {
      position: absolute;
      right: -0.9vw;
      top: -0.9vw;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 50%;
      padding: 0.3vw;
      cursor: pointer;
      z-index: 11;
      box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.05);
      .MuiSvgIcon-root{
        font-size: 1.5vw;
      }
    }

    .header-container {
      background: #fffaf3;
      gap: 1vw;
      padding: 1.25vw 1.75vw;
      text-align: start;
      background-image: url('../../assets/support-agent.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      border-radius: 12px 12px 0 0;

      .header-title {
        text-transform: uppercase;
        color: #6c757d;
        font-weight: 600;
        font-size: 1.1vw;
      }

      .header-subtitle {
        font-weight: 600;
        font-size: 1.5vw;
        color: #0d0d0d;
        letter-spacing: 0.05em;
      }

      .header-detail {
        font-weight: 500;
        font-size: 1.05vw;
        color: #343a40;
      }
    }

    .data-container {
      overflow: auto;
      flex: 1 1 auto;

      &::-webkit-scrollbar {
        width: 17px !important;
      }

      .line {
        width: 100%;
        height: 1.25px;
        background: #e9ecef;
        align-self: center;
        margin: 1.75vw auto;
      }

      .questions-container {
        padding: 0 1.75vw;

        .question-inner-container {
          .question {
            cursor: pointer;
            color: #343a40;
            font-size: 1.15vw;
            font-weight: 700;
          }

          .answer-container {
            gap: 1.75vw;
            padding-top: 0.5vw;

            .answer {
              color: #343a40;
              font-size: 1.05vw;
              font-weight: 500;
            }

            .contact-container {
              gap: 1.5vw;

              .contact-inner-container {
                border: 1.25px solid #e9ecef;
                box-sizing: border-box;
                border-radius: 10px;
                padding: 0.75vw;
                gap: 0.5vw;
                align-items: center;
                cursor: default;

                .icon {
                  max-height: 1.5vw;
                  width: 100%;
                  max-width: 1.5vw;
                }

                .contact {
                  font-size: 1.15vw;
                  white-space: nowrap;
                }

                &:hover {
                  border: 1.25px solid #bf5af2;
                }
              }
            }
          }
        }
      }

      .footer-text {
        padding: 1.5vw 1.75vw;
        font-size: 1.05vw;
        font-weight: 500;
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .outer-container {
    .inner-container {
      width: 80vw;
      height: 50vw;

      .header-container {
        padding: 2.5vw 3vw;

        .header-title {
          font-size: 1.5vw;
        }

        .header-subtitle {
          font-size: 2.25vw;
        }

        .header-detail {
          font-size: 1.35vw;
        }
      }

      .data-container {
        .questions-container {
          padding: 0 3vw;

          .question-inner-container {
            .question {
              font-size: 1.5vw;
            }

            .answer-container {
              gap: 2vw;

              .answer {
                font-size: 1.35vw;
              }

              .contact-container {
                gap: 1.5vw;

                .contact-inner-container {
                  .contact {
                    font-size: 1.5vw;
                  }
                }
              }
            }
          }
        }

        .footer-text {
          font-size: 1.35vw;
          padding: 2vw 3vw;
        }
      }
    }
  }
}