#loader {
  width: 8vw;
  height: 8vw;
}

@media only screen and (max-width: 600px) {
  #loader {
    width: 20vw;
    height: 20vw;
  }
}
