.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);

  .popup {
    position: absolute;
    max-width: 460px;
    height: fit-content;
    border-radius: 10px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;

    .close-btn {
      position: absolute;
      top: -12px;
      right: -12px;
      width: 32px;
      height: 32px;
      display: flex;
      background: white;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      z-index: 10;
    }

    .header {
      width: 100%;
      height: 132px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: rgba(0, 106, 255, 0.1);
      padding: 20px 25px;
      overflow: hidden;
      position: relative;

      .icon {
        position: absolute;
        top: -45px;
        right: -55px;
        background: url('../../assets/settings.svg');
        background-size: cover;
        width: 150px;
        height: 150px;
      }

      .heading {
        color: #6c757d;
        font-size: 14px;
        font-weight: 500;
      }

      .title {
        font-size: 24px;
        font-weight: 600;
        color: black;
        margin-top: 5px;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 500;
        color: #343a40;
        text-align: left;
        margin-top: 5px;
      }
    }

    .settings-body {
      background: white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .permission-row {
        padding: 20px;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 15px;
          background: white;
        }

        .title {
          font-size: 20px;
          font-weight: 600;
          color: black;
        }

        .subtitle {
          font-size: 14px;
          font-weight: 500;
          margin-top: 3px;
          color: #6c757d;
        }

        .good {
          border: none;
          font-size: 14px;
          font-weight: 500;
          border-radius: 12px;
          padding: 10px 16px;
          background: rgba(27, 181, 92, 0.2);
          color: #1bb55c;
          width: 138px;

          &::before {
            margin-right: 8px;
            content: url('../../assets/tick.svg');
          }
        }

        .browser-status {
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          border-radius: 12px;
          padding: 10px 16px;
          width: 138px;
          white-space: nowrap;

          &.bad {
            background: rgba(221, 35, 76, 0.2);
            color: #dd234c;
          }
        }

        .allow-btn {
          border: none;
          font-size: 14px;
          font-weight: 500;
          border-radius: 12px;
          padding: 10px 16px;
          background-color: #bf5af2;
          color: white;
          cursor: pointer;
          box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.22);
          width: 138px;
          white-space: nowrap;

          &:hover:enabled {
            background-color: #b642f0;
          }

          &[disabled] {
            cursor: unset !important;
            box-shadow: unset !important;
          }
        }
      }

      .warning-message {
        color: red;
        font-weight: 500;
        font-size: 14px;
      }

      .continue-btn {
        box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.22);
        background-color: #bf5af2;
        color: white;
        border-radius: 12px;
        font-size: 18px;
        font-weight: 600;
        margin: 20px auto;
        cursor: pointer;
        border: none;
        padding: 10px 24px;

        &[disabled] {
          box-shadow: unset;
          opacity: 0.4;
          cursor: default;
        }

        &:hover:enabled {
          background-color: #b642f0;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .popup-container {
    display: none;
  }
}
